import React from "react"
import Layout from "../../components/layout"
import { Container, Row, Col } from "react-bootstrap"
import "react-id-swiper/lib/styles/css/swiper.css"
import { Link } from "gatsby"

const erpcrmPage = location => {
  return (
    <Layout location={location} page="technology-erpcrm">
      <div className="erpcrm-page" style={{ paddingTop: "100px" }}>
        <div className="erpcrm-heading">
          <div className="container">
            <h1 className="text-blue text-white text-center mb-5 relative">
              ERP & CRM
            </h1>
            <h3 className="relative text-white mb-4">
              Rely on our subject matter experts to make your search a success.
            </h3>
            <div className="flex flex-wrap justify-content-between align-items-center">
              <Link
                className="white-btn block relative mr-md-2"
                to="/contact-us"
              >
                Find ERP & CRM Talent
              </Link>{" "}
              <Link className="white-btn block relative" to="/jobs">
                Find ERP & CRM Jobs
              </Link>
            </div>
          </div>
        </div>
        <Container>
          <div className="py-100">
            <Row className="justify-content-center">
              <Col lg={9}>
                <h3>
                  The success of any ERP or CRM initiative relies on great
                  people...
                </h3>
                <h4 className="text-primary py-3">Let’s find yours.</h4>
                <p>
                  Whether you’re migrating to the cloud or just need some
                  additional support for your systems, the choice of
                  professionals to staff your roles is critical. Selecting the
                  right talent is paramount to ensure the successful deployment
                  and seamless operation of ERP and CRM systems. The expertise
                  and skills of the professionals involved directly influence
                  the efficiency, scalability, and performance outcomes of these
                  platforms. Our skilled consultants can assist with everything
                  from discovery and selection through to data migration,
                  testing, deployment, and ongoing support.
                </p>
                <p>
                  Our experts are knowledgeable in a wide variety of ERP and CRM
                  technologies, including:
                </p>
                <Row className="justify-content-center">
                  <Col md={4}>
                    <ul className="checked-list lead">
                      <li>Dynamics 365</li>
                      <li>Oracle Cloud</li>
                      <li>Oracle NetSuite</li>
                      <li>Oracle PeopleSoft</li>
                      <li>Workday</li>
                      <li>SAP S/4HANA</li>
                    </ul>
                  </Col>
                  <Col md={4}>
                    <ul className="checked-list lead">
                      <li>Infor M3</li>
                      <li>Epicor ERP</li>
                      <li>SageX3</li>
                      <li>Odoo ERP</li>
                      <li>Acumatica Cloud</li>
                      <li>Deltek</li>
                    </ul>
                  </Col>
                  <Col md={4}>
                    <ul className="checked-list lead">
                      <li>Salesforce</li>
                      <li>Dynamics 365 CRM</li>
                      <li>Zoho CRM</li>
                      <li>HubSpot</li>
                      <li>Oracle NetSuite CRM</li>
                      <li>SugarCRM</li>
                    </ul>
                  </Col>
                </Row>
                <Link
                  className="common-btn block relative mb-5"
                  to="/contact-us"
                >
                  Hire ERP & CRM Talent
                </Link>
                <h4 className="mb-4">The ERP & CRM Professionals We Place</h4>
                <Row className="justify-content-center mb-5">
                  <Col md={4}>
                    <ul className="checked-list lead">
                      <li>Implementation Consultant</li>
                      <li>Business Analyst</li>
                      <li>Technical Consultant</li>
                      <li>ERP Developer</li>
                      <li>CRM Developer</li>
                      <li>Integration Analyst</li>
                      <li>Data Conversion Analyst </li>
                    </ul>
                  </Col>
                  <Col md={4}>
                    <ul className="checked-list lead">
                      <li>Report Developer</li>
                      <li>Project Manager</li>
                      <li>Customer Success Manager</li>
                      <li>Sales Engineer </li>
                      <li>Solution Architect</li>
                      <li>ERP Administrator</li>
                      <li>CRM Administrator</li>
                    </ul>
                  </Col>
                  <Col md={4}>
                    <ul className="checked-list lead">
                      <li>Financial Systems Analyst</li>
                      <li>HRIS Systems Analyst</li>
                      <li>SCM Systems Analyst</li>
                      <li>ERP Trainer</li>
                      <li>CRM Trainer</li>
                      <li>Managing Director</li>
                      <li>Executive Leadership</li>
                    </ul>
                  </Col>
                </Row>
                <h3>Propel Your Career Forward</h3>
                <h6 className="text-dark py-2">
                  Are you an ERP or CRM expert? Looking to advance your career,
                  or explore management opportunities?
                </h6>
                <h6 className="text-dark py-2">
                  Let’s take the next step together.
                </h6>
                <h6 className="text-dark py-2">
                  PeerSource has ERP and CRM jobs available right now – and with
                  our outstanding benefits, unwavering support, and longstanding
                  industry experience, we’re here to make your search easy and
                  effective.
                </h6>
                <Link className="common-btn block relative" to="/jobs">
                  Explore Opportunities
                </Link>
              </Col>
            </Row>
          </div>
        </Container>
        <Container
          fluid
          className="benefit-tech text-center"
          style={{ padding: "100px 0", "background-color": "#0f2848" }}
        >
          <Container>
            <h2 className="text-white">Get Started with PeerSource!</h2>
            <h3
              className="text-center btech-flair mb-5 text-white text-center"
              style={{ maWidth: "33em", margin: "0 auto" }}
            >
              How can we help?
            </h3>
            <div className="flex flex-wrap justify-content-between align-items-center">
              <Link
                className="white-btn border-none mr-md-2"
                to="/contact-us"
                style={{ fontSize: "20px" }}
              >
                Find Professionals
              </Link>
              <Link
                className="white-btn border-none"
                to="/jobs"
                style={{ fontSize: "20px" }}
              >
                Browse Jobs
              </Link>
            </div>
          </Container>
        </Container>
      </div>
    </Layout>
  )
}

export default erpcrmPage
